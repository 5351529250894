/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Row } from "reactstrap";
import StudentToolLink from "components/StudentToolLink/StudentToolLink";

const campusColor = ["#6ad0b3", "#fd7c6e", "#fbc657"];

class Tools extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      Student: {
        Subjects: [],
        Messages: [],
        Invoices: [],
      },
      AttendanceChartList: [],
      Message: {},
      ModalMessageIsOpen: false,
      SelectClassIdForAssignmentModal: "",
      AssignmentModalIsOpen: false,
    };
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <StudentToolLink
              url={"https://outlook.office365.com/mail/inbox"}
              imgUrl={"https://hubstorage.lumos.edu/img/email.png"}
              name={"Access Email"}
            />

            <StudentToolLink
              url={"https://homework.lumosls.com/login/index.php"}
              imgUrl={"https://hubstorage.lumos.edu/img/lms.png"}
              name={"Lumos LMS"}
            />

            <StudentToolLink
              url={"https://form.jotform.com/221867618462161"}
              imgUrl={"https://hubstorage.lumos.edu/img/complain.png"}
              name={"Formal Complaints"}
            />

            <StudentToolLink
              url={"https://media.lumosls.edu"}
              imgUrl={"https://hubstorage.lumos.edu/img/audio.png"}
              name={"Audio Books"}
            />

            <StudentToolLink
              url={"http://lumos.yclas.com/"}
              imgUrl={"https://hubstorage.lumos.edu/img/market.png"}
              name={"Marketplace"}
            />

            <StudentToolLink
              url={"https://reg.lumos.edu"}
              imgUrl={"https://hubstorage.lumos.edu/img/apply-now-100x100.png"}
              name={"Self-registration"}
            />
          </Row>
        </div>
      </>
    );
  }

  async componentDidMount() {}

  componentWillUnmount() {}
}

export default Tools;
