/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Button,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import myData from "../Countries.json";
import {
  FormatFullDateList,
  FormatMonthYearDateList,
  editMode,
} from "helpers/Utils.js";
import { SavePdf } from "components/Report/GeneratePdf.js";
import * as dateFns from "date-fns";
import { Doughnut, Bar } from "react-chartjs-2";
import { colorArray } from "colors.js";
import ModalMessage from "components/Message/Message.Component";
import CustomCalendar from "../components/Calendar/Calendar.component";
import id from "../ID.png";

const campusColor = ["#6ad0b3", "#fd7c6e", "#fbc657"];

class StudentId extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  componentDidMount() {
    axios
      .get("students/byId")
      .then((response) => {
        const nameParts = response.data.data.name
          .toUpperCase()
          .trim()
          .split(" ");

        this.setState({
          firstName: nameParts[0],
          lastName: nameParts[nameParts.length - 1],
          i20: response.data.data.i20,
          imageData: response.data.data.pictureUrl,
          campus:
            (response.data.data.sevis.name.toUpperCase() ==
            "SALT LAKE IN PERSON"
              ? "SLC"
              : response.data.data.sevis.name.toUpperCase() == "OREM IN PERSON"
              ? "Orem"
              : response.data.data.sevis.name) + " Campus",
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }

  getMainState = () => {
    return { imageData: "", firstName: "", lastName: "", campus: "", i20: "" };
  };

  render() {
    return (
      <div className="content">
        <div className="image-container">
          {this.state.imageData ? (
            <img src={this.state.imageData} className="background-image" />
          ) : (
            <p>Loading image...</p>
          )}

          <img src={id} alt="Círculo vazado" className="overlay-image" />
          <div className="text-container">
            <div className="name">
              <div className="first-name">{this.state.firstName}</div>
              <div className="last-name">{this.state.lastName}</div>
            </div>
            <div className="campus">{this.state.campus}</div>
            <div className="studentId">STUDENT ID</div>
            <div className="gray-label">{this.state.i20}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default StudentId;
