import Dashboard from "views/Dashboard.jsx";
import Attendance from "views/Attendance";
import Messages from "views/Messages";
import Calendar from "views/Calendar";
import TestRetake from "views/TestRetake";
import Tools from "views/Tools";
import Referral from "views/Referral";
import StudentId from "views/StudentId";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-bar-32",
    component: Dashboard,
    layout: "/admin",
  },

  {
    path: "/attendance",
    name: "Attendance",
    icon: "fas fa-check",
    component: Attendance,
    layout: "/admin",
  },

  {
    path: "/message",
    name: "Message",
    icon: "fas fa-envelope",
    component: Messages,
    layout: "/admin",
  },

  {
    path: "/lumoscalendar",
    name: "Lumos Calendar",
    icon: "fas fa-calendar-alt",
    component: Calendar,
    layout: "/admin",
  },

  {
    path: "/testretake",
    name: "Missed test",
    icon: "fas fa-edit",
    component: TestRetake,
    layout: "/admin",
  },

  {
    path: "/studenttools",
    name: "Student Tools",
    icon: "nc-icon nc-settings",
    component: Tools,
    layout: "/admin",
  },

  // {
  //   path: "/referral",
  //   name: "Referral",
  //   icon: "nc-icon nc-spaceship",
  //   component: Referral,
  //   layout: "/admin",
  // },

  {
    path: "/StudentId",
    name: "Student ID",
    icon: "nc-icon nc-spaceship",
    component: StudentId,
    layout: "/admin",
  },

  // {
  //   path: "/selfenrollment",
  //   name: "Self-enrollment",
  //   icon: "fas fa-tasks",
  //   component: Dashboard,
  //   layout: "/admin",
  // },

  // {
  //   path: "/profile",
  //   name: "Profile",
  //   icon: "fas fa-user",
  //   component: Dashboard,
  //   layout: "/admin",
  // },
];
export default routes;
